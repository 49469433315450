import styled, { keyframes } from 'styled-components';
import AnimationRoot from './AnimationRoot';

const FadeInUpAnimation = keyframes`  
  from { opacity: 0; -webkit-transform: translateY(30px);}
  to { opacity: 1; -webkit-transform: translateY(0);}
`; 
const FadeInUp = styled(AnimationRoot)`
  animation-name: ${FadeInUpAnimation};
`; 
export default FadeInUp;