import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const OurClients = props => {
  const data = useStaticQuery(graphql`{
  allOurClientsDataJson {
    edges {
      node {
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, width: 125, layout: CONSTRAINED, placeholder: NONE)
          }
        }
        url
      }
    }
  }
}
`);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 576 },
      items: 5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  }
  return (
    <Carousel
      className="myCarousel"
      responsive={responsive}
      autoPlay={props.deviceType !== "mobile" ? true : false}
      autoPlaySpeed={4000}
      customTransition="all 2s"
      transitionDuration={2000}
      infinite={true}
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      draggable={true}
    >
      {data.allOurClientsDataJson.edges.map((item, index) => {
        
        return (
          <div className="carouselItems" key={index}>
            <a target="_blank" rel="noopener noreferrer" href={item.node.url}>
              <GatsbyImage
                image={item.node.image.childImageSharp.gatsbyImageData}
                className="client"
                alt="clientCard"
                loading="eager" />
            </a>
          </div>
        );
      })}
    </Carousel>
  );
}

export default OurClients
