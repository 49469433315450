import React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const HomeCards = () => {
	const images = useStaticQuery(graphql`{
  machineIcon: file(relativePath: {eq: "homeCards/machineIcon.png"}) {
    childImageSharp {
      gatsbyImageData(width: 48, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  machineHover: file(relativePath: {eq: "homeCards/machineHover.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  packagingIcon: file(relativePath: {eq: "homeCards/packagingIcon.png"}) {
    childImageSharp {
      gatsbyImageData(width: 48, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  packagingHover: file(relativePath: {eq: "homeCards/packagingHover.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  productsIcon: file(relativePath: {eq: "homeCards/productsIcon.png"}) {
    childImageSharp {
      gatsbyImageData(width: 48, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
  productsHover: file(relativePath: {eq: "homeCards/productsHover.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED, placeholder: NONE)
    }
  }
}
`);

	return <>
        <div className="homeCardsContainer">
            <div className="homeCards">
                <Link to="/machines">
                    
                        <div className="cube spin-top">
                            <div className="face-front">
                                <GatsbyImage
                                    image={images.machineIcon.childImageSharp.gatsbyImageData}
                                    className="myIcon"
                                    alt="cardIcon"
                                    loading="eager" />
                                <h4 className="cubeText">
                                    <FormattedMessage id="machineSelection.cardSelection" />
                                </h4>
                            </div>

                            <div className="face-top">
                                <GatsbyImage
                                    image={images.machineHover.childImageSharp.gatsbyImageData}
                                    className="imageHover"
                                    alt="cardIcon"
                                    loading="eager" />
                            </div>
                        </div>
                    
                </Link>
            </div>

            <div className="homeCards">
                <Link to="/packaging">
                    
                        <div className="cube spin-top">
                            <div className="face-front">
                                <GatsbyImage
                                    image={images.packagingIcon.childImageSharp.gatsbyImageData}
                                    className="myIcon"
                                    alt="cardIcon"
                                    loading="eager" />
                                <h4 className="cubeText">
                                    <FormattedMessage id="packaging.packagingWish" />
                                </h4>
                            </div>

                            <div className="face-top">
                                <GatsbyImage
                                    image={images.packagingHover.childImageSharp.gatsbyImageData}
                                    className="imageHover"
                                    alt="cardIcon"
                                    loading="eager" />
                            </div>
                        </div>
                    
                </Link>
            </div>

            <div className="homeCards">
                <Link to="/products">
                    
                        <div className="cube spin-top">
                            <div className="face-front">
                                <GatsbyImage
                                    image={images.productsIcon.childImageSharp.gatsbyImageData}
                                    className="myIcon"
                                    alt="cardIcon"
                                    loading="eager" />
                                <h4 className="cubeText">
                                    <FormattedMessage id="products.productsWish" />
                                </h4>
                            </div>

                            <div className="face-top">
                                <GatsbyImage
                                    image={images.productsHover.childImageSharp.gatsbyImageData}
                                    className="imageHover"
                                    alt="cardIcon"
                                    loading="eager" />
                            </div>
                        </div>
                    
                </Link>
            </div>
        </div>
    </>;
};

export default HomeCards;
