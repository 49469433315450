import React from "react";
import SEO from "../components/seo/seo";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { graphql, useStaticQuery } from "gatsby";
import HomeCards from "../components/homeCards/HomeCards";
import OurClients from "../components/carousel/OurClients";
import BackgroundSlider from "gatsby-image-background-slider";
import sectionPicDark from "../images/homePage/sectionPicDark.png"
import FadeInUp from "../components/animations/FadeInUp";

const IndexPage = ({ intl }) => {
	return <>
        <SEO title={intl.formatMessage({ id: "navbar.home" })} />

        <div className="tehnomatikSectionHome">
            <div className="tehnomatikHomeBackgroundSection">
            <BackgroundSlider
						query={useStaticQuery(graphql`
							query {
								backgrounds: allFile(
									filter: { sourceInstanceName: { eq: "backgrounds" } }
								) {
									nodes {
										relativePath
										childImageSharp {
											fluid(maxWidth: 1920, quality: 100) {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
						`)}
						className="tehnomatikHomeBackgroundImages"
						initDelay={2}
						transition={4}
						duration={5}
						loading="eager"
					></BackgroundSlider>
                <div className="tehnomatikHomeBackgroundTitle">
                    <p>Tehnomatik 1995</p>
                
                    <Link to="/about">
                        <button className="learnMoreButton">
                        <FormattedMessage id="index.section01.learn" />
                        </button>
                    </Link>
                </div>
            </div>

            <div className="indexPageCardSection">
                <div className="indexPageCardTitleDiv">
                    <p className="indexPageCardTitle">
                        {intl.formatMessage({ id: "index.meet" })}
                    </p>
                    <hr className="homeLine" />
                </div>
                <div className="indexCardsDiv">
                    <FadeInUp duration="1s" delay="0.5s"><HomeCards /></FadeInUp>
                </div>
            </div>

            <div className="indexPageManufacture">
                <img src={sectionPicDark} alt="sectionPic"/>
                <p>{intl.formatMessage({ id: "index.section01.text" })}</p>
            </div>
        </div>

        <div className="indexPageClientsSection">
            <svg
                className="clientsDividerSmall"
                viewBox="0 0 375 38"
                fill="none"
                alt="tehnomatikSvg"
            >
                <path
                    d="M187.5 38C87.734 38 0 0 0 0H375C375 0 287.266 38 187.5 38Z"
                    fill="white"
                />
            </svg>
            <svg
                className="clientsDividerMid"
                viewBox="0 0 768 50"
                fill="none"
                alt="tehnomatikSvg"
            >
                <path
                    d="M384 49.5C179.679 49.5 0 0 0 0H768C768 0 588.321 49.5 384 49.5Z"
                    fill="white"
                />
            </svg>
            <svg
                className="clientsDividerLg"
                viewBox="0 0 1920 66"
                fill="none"
                alt="tehnomatikSvg"
            >
                <path
                    d="M960.5 65.5C449.698 65.5 0 0 0 0H1920C1920 0 1471.3 65.5 960.5 65.5Z"
                    fill="white"
                />
            </svg>

            <p className="indexPageClientsTitle">
                {intl.formatMessage({ id: "index.section03.clients" })}
            </p>

            <div className="ourClientsDiv">
                <OurClients />
            </div>
        </div>
    </>;
};
export default injectIntl(IndexPage);
